import * as actions from '../Actions/modal.actions';
import { SecurityModalType, TaggingSource, FormPostKeyValue } from 'shared';

export interface ModalState {
  securityModal: boolean;
  securityModalDescription: string | JSX.Element;
  securityModalMessage: string;
  securityModalType: SecurityModalType;
  cancelledPolicyModal: boolean;
  cancelledPolicyDescription: string;
  cancelledPolicyMessage: string;
  taggingSource: TaggingSource;
  extraFields: FormPostKeyValue[];
  payBillUrl: string;
}

export const initialState = {
  securityModal: false,
  securityModalDescription: '',
  securityModalMessage: '',
  securityModalType: SecurityModalType.MEMBERSHIP,
  cancelledPolicyModal: false,
  cancelledPolicyDescription: '',
  cancelledPolicyMessage: '',
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_MODAL:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
