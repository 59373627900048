export const DefaultContactCard = ({ paymentsURL, contactUsURL }: { paymentsURL: string; contactUsURL: string }) => {
  return (
    <div
      style={{
        margin: '25px 10px',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
      data-testid="default-contact"
    >
      <p style={{ fontSize: '20px', fontWeight: 500, margin: '10px 0', lineHeight: '32px' }}>
        I don't see my policy. What can I do?
      </p>
      <p style={{ fontSize: '16px', lineHeight: 1.5, margin: '5px 0' }}>
        <a
          style={{ color: '#1C4DA6', textDecoration: 'none', fontWeight: 500 }}
          href={paymentsURL}
          target="_blank"
          rel="noreferrer"
        >
          Make an insurance payment
        </a>
      </p>
      <p style={{ fontSize: '16px', lineHeight: 1.5, margin: '5px 0' }}>
        <a
          style={{ color: '#1C4DA6', textDecoration: 'none', fontWeight: 500 }}
          href={contactUsURL}
          target="_blank"
          rel="noreferrer"
        >
          Contact us
        </a>
      </p>
    </div>
  );
};
