import * as actions from '../Actions/user.actions';
import { AppPermissions, ClubCodes, CustomerDetailsProduct } from 'shared';

export interface UserState {
  customerId: string;
  membershipNumber: string;
  clubCode: ClubCodes;
  customerName: string;
  lastName: string;
  state: string;
  permissions: AppPermissions[];
  sessionId: string;
  pageLoadSuccessful?: boolean;
  products: CustomerDetailsProduct[];
}

export const initialState = {
  customerId: '',
  customerName: '',
  lastName: '',
  clubCode: '',
  state: '',
  permissions: [],
  products: [],
  sessionId: '',
  pageLoadSuccessful: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_USER:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
