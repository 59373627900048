import { ConfigResponseSource, ConfigProperties } from '../models/configModel';
import { ClubCodes } from '../common/enums';

// club-level config - ex. 004-config.json
const configCA: ConfigProperties = {
  club: '004',
  clubDescription: 'California',
  clubDomain: 'https://www.calif.aaa.com',
  clubAppDomain: 'https://apps.calif.aaa.com',
  cludoID: 'ca-cludo-id',
  MembershipFullAccess: ['PR', 'AD'],
  getAutoQuoteURL: 'https://uatapps2.calif.aaa.com/insurance/auto/olb',
  Maintenance: {
    ScheduleByDays: {
      '0': {
        StartTime: '03:00',
        EndTime: '05:00',
      },
      '1': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '2': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '3': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '4': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '5': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '6': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
    },
    RoadsideUrl: 'http://apps.<domain>.aaa.com/aceapps/links/alp/AAARoadsideAssistance',
    HelpPhoneNumber: '1-800-400-4222',
  },
};
const configTX: ConfigProperties = {
  club: '252',
  clubDescription: 'Texas',
  clubDomain: 'https://www.texas.aaa.com',
  clubAppDomain: 'https://apps.texas.aaa.com',
  cludoID: 'tx-cludo-id',
  MembershipFullAccess: ['PR', 'AD'],
  getAutoQuoteURL: 'https://apps2.texas.aaa.com/insurance/auto/olb',
};
const configNM: ConfigProperties = {
  club: '601',
  clubDescription: 'New Mexico',
  clubDomain: 'https://www.newmexico.aaa.com',
  clubAppDomain: 'https://apps.newmexico.aaa.com',
  cludoID: 'nm-cludo-id',
  MembershipFullAccess: ['PR', 'AD'],
};
const configHI: ConfigProperties = {
  club: '018',
  clubDescription: 'Hawaii',
  clubDomain: 'https://www.hawaii.aaa.com',
  clubAppDomain: 'https://apps.hawaii.aaa.com',
  cludoID: 'hi-cludo-id',
  MembershipFullAccess: ['PR', 'AD'],
};
const configNNE: ConfigProperties = {
  club: '036',
  clubDescription: 'Northern New England',
  clubDomain: 'https://www.northernnewengland.aaa.com',
  clubAppDomain: 'https://apps.northernnewengland.aaa.com',
  cludoID: 'nne-cludo-id',
  MembershipFullAccess: ['PR'],
};
const configEC: ConfigProperties = {
  club: '215',
  clubDescription: 'East Central',
  clubDomain: 'https://www.eastcentral.aaa.com',
  clubAppDomain: 'https://apps.eastcentral.aaa.com',
  cludoID: 'ec-cludo-id',
  MembershipFullAccess: ['PR', 'AD'],
};
const configTW: ConfigProperties = {
  club: '258',
  clubDescription: 'Tidewater',
  clubDomain: 'https://www.tidewater.aaa.com',
  clubAppDomain: 'https://apps.tidewater.aaa.com',
  cludoID: 'tw-cludo-id',
  MembershipFullAccess: ['PR', 'AD'],
};
const configMO: ConfigProperties = {
  club: '065',
  clubDescription: 'Missouri',
  clubDomain: 'https://www.autoclubmo.aaa.com',
  clubAppDomain: 'https://apps.autoclubmo.aaa.com',
  cludoID: 'acmo-cludo-id',
  MembershipFullAccess: ['PR', 'AD'],
  getAutoQuoteURL: 'https://apps.autoclubmo.aaa.com/aceapps/insurance/quotes/ShortForm/GetStarted?ip=1',
  getAutoQuoteURLMO: 'https://apps2.autoclubmo.aaa.com/aceapps/insurance/quote/contact',
};

export const configHeaderFooterFixture: ConfigProperties = {
  customerName: 'Marcy',
  roadsideAssistanceURL: 'https://www.aaa.com/partnerlink?PID=1147',
  findBranchURL: 'https://www.aaa.com/office/',
  myAccountURL: 'https://apps.eastcentral.aaa.com/aceapps/account/my-account',
  membershipURL: 'https://www.eastcentral.aaa.com/?productLink=nav-0',
  insuranceURL: 'https://www.eastcentral.aaa.com/?productLink=nav-1',
  travelURL: 'https://www.eastcentral.aaa.com/?productLink=nav-2',
  discountsURL: 'https://www.eastcentral.aaa.com/?productLink=nav-3',
  automotiveURL: 'https://www.eastcentral.aaa.com/?productLink=nav-4',
  financialURL: 'https://www.eastcentral.aaa.com/?productLink=nav-5',
  selfServeURL: 'https://www.eastcentral.aaa.com/?productLink=nav-6',
  headerLogoURL: 'https://www.eastcentral.aaa.com/content/dam/ace/logo/orbit-logo.png',
  footerLogoURL: 'https://www.eastcentral.aaa.com/content/dam/ace/logo/aaa-logo-white.png',
  privacyPolicyURL: 'https://www.eastcentral.aaa.com/legal/privacy.html',
  termsAndConditionsURL: 'https://www.eastcentral.aaa.com/legal/terms-of-use.html',
  doNotSellURL: '',
  contactUsURL: 'https://www.eastcentral.aaa.com/information/contact-us.html',
  memberGuideURL: 'https://www.calif.aaa.com/content/dam/ace/pdf/EC-MemberGuide.pdf',
  downloadAppURL: 'https://www.eastcentral.aaa.com/automotive/driver-resources/auto-club-app/download.html',
  facebookURL: 'https://www.facebook.com/AAAEastCentral',
  instagramURL: 'https://www.instagram.com/aaaecentral',
  twitterURL: 'https://twitter.com/aaaecentral',
  copyright: 'Copyright &copy; <year> AAA East Central. All Rights Reserved.',
  disclaimer:
    'AAA East Central is a member club affiliated with the American Automobile Association (AAA) national federation and serves members in Kentucky, New York, Ohio, Pennsylvania and West Virginia.',
};

// header/footer config -- 004-headerfooter-config.json
export const configHeaderFooterFixtureCA: ConfigProperties = {
  roadsideAssistanceURL: 'https://www.aaa.com/partnerlink?PID=1147',
  findBranchURL: 'https://www.aaa.com/office/',
  myAccountURL: 'https://apps.calif.aaa.com/aceapps/account/my-account',
  membershipURL: 'https://www.calif.aaa.com/?productLink=nav-0',
  insuranceURL: 'https://www.calif.aaa.com/?productLink=nav-1',
  travelURL: 'https://www.calif.aaa.com/?productLink=nav-2',
  discountsURL: 'https://www.calif.aaa.com/?productLink=nav-3',
  automotiveURL: 'https://www.calif.aaa.com/?productLink=nav-4',
  financialURL: 'https://www.calif.aaa.com/?productLink=nav-5',
  selfServeURL: 'https://www.calif.aaa.com/?productLink=nav-6',
  headerLogoURL: 'https://www.calif.aaa.com/content/dam/ace/logo/orbit-logo.png',
  footerLogoURL: 'https://www.calif.aaa.com/content/dam/ace/logo/aaa-logo-white.png',
  privacyPolicyURL: 'https://www.calif.aaa.com/legal/privacy.html',
  termsAndConditionsURL: 'https://www.calif.aaa.com/legal/terms-of-use.html',
  doNotSellURL: 'https://apps2.calif.aaa.com/cpa/request',
  contactUsURL: 'https://www.calif.aaa.com/information/contact-us.html',
  memberGuideURL: 'https://www.calif.aaa.com/content/dam/ace/pdf/CA-MemberGuide.pdf',
  downloadAppURL: 'https://www.calif.aaa.com/automotive/driver-resources/auto-club-app/download.html',
  facebookURL: 'https://www.facebook.com/AAASoCal',
  instagramURL: 'https://www.instagram.com/aaasocal',
  twitterURL: 'https://twitter.com/aaasocal',
  copyright: 'CST 1016202-80 Copyright &copy; <year> Automobile Club of Southern California. All Rights Reserved.',
  disclaimer:
    'The Automobile Club of Southern California is a member club affiliated with the American Automobile Association (AAA) national federation and serves members in the following California counties: Inyo, Imperial, Kern, Los Angeles, Mono, Orange, Riverside, San Bernardino, San Diego, San Luis Obispo, Santa Barbara, Tulare, and Ventura.',
};

export const configHeaderFooterFixtureEC: ConfigProperties = {
  roadsideAssistanceURL: 'https://www.aaa.com/partnerlink?PID=1147',
  findBranchURL: 'https://www.aaa.com/office/',
  myAccountURL: 'https://apps.eastcentral.aaa.com/aceapps/account/my-account',
  membershipURL: 'https://www.eastcentral.aaa.com/?productLink=nav-0',
  insuranceURL: 'https://www.eastcentral.aaa.com/?productLink=nav-1',
  travelURL: 'https://www.eastcentral.aaa.com/?productLink=nav-2',
  discountsURL: 'https://www.eastcentral.aaa.com/?productLink=nav-3',
  automotiveURL: 'https://www.eastcentral.aaa.com/?productLink=nav-4',
  financialURL: 'https://www.eastcentral.aaa.com/?productLink=nav-5',
  selfServeURL: 'https://www.eastcentral.aaa.com/?productLink=nav-6',
  headerLogoURL: 'https://www.eastcentral.aaa.com/content/dam/ace/logo/orbit-logo.png',
  footerLogoURL: 'https://www.eastcentral.aaa.com/content/dam/ace/logo/aaa-logo-white.png',
  privacyPolicyURL: 'https://www.eastcentral.aaa.com/legal/privacy.html',
  termsAndConditionsURL: 'https://www.eastcentral.aaa.com/legal/terms-of-use.html',
  doNotSellURL: '',
  contactUsURL: 'https://www.eastcentral.aaa.com/information/contact-us.html',
  memberGuideURL: 'https://www.calif.aaa.com/content/dam/ace/pdf/EC-MemberGuide.pdf',
  downloadAppURL: 'https://www.eastcentral.aaa.com/automotive/driver-resources/auto-club-app/download.html',
  facebookURL: 'https://www.facebook.com/AAAEastCentral',
  instagramURL: 'https://www.instagram.com/aaaecentral',
  twitterURL: 'https://twitter.com/aaaecentral',
  copyright: 'Copyright &copy; <year> AAA East Central. All Rights Reserved.',
  disclaimer:
    'AAA East Central is a member club affiliated with the American Automobile Association (AAA) national federation and serves members in Kentucky, New York, Ohio, Pennsylvania and West Virginia.',
};

// application.json config
export const configFixtureApp: ConfigProperties = {
  club: '252',
  env: 'prod-green',
  WEB_METRICS_ENABLED: true,
  CACHE_ENABLED: false,
  ENABLE_HTTPS: true,
  LOGGER_LEVEL: 'error',
  Tab1: 'My account',
  Tab2: 'Pay bills',
  Tab3: 'Profile',
  authFlow: 'token',
  HomeURL: 'https://www.<domain>.aaa.com',
  ReferrerURL: 'https://apps2.<clubName>.aaa.com/aceapps/myaccount',
  LoginUrl:
    'https://apps.<clubName>.aaa.com/aceapps/authenticate2/authcodelogin?type=<authFlow>&securityKey=myacct&authCodeReferrerUrl=<encodedReferrerUrl>&authCodeReturnUrl=<encodedReturnUrl>',
  Apps2DomainCookieName: 'aceut',
  Apps2DomainCustomerIdCookieName: 'aceu',
  logoutURL: 'https://apps.<domain>.aaa.com/aceapps/authenticate2/logout',
  manageAutoPayURL: 'http://appuat1.ace.aaa.com/payments/manage-autopay',
  managePasswordURL: 'https://apps.<domain>.aaa.com/aceapps/account-maintenance/login-details/view',
  managePaymentAccountsURL: 'https://apps.<domain>.aaa.com/aceapps/payments/PaymentAccount/ProcessPaymentAccount',
  manageCommPrefsURL: 'https://apps.<domain>.aaa.com/aceapps/preferences/start?paccpt=webmanage',
  InsuranceDiscountURL: 'https://www.<domain>.aaa.com/insurance/insurance-resources/insurance-discounts.html', // deprecated?
  InsuranceFAQURL: 'https://www.<domain>.aaa.com/insurance/insurance-resources/insurance-discounts.html#faqs', // deprecated?
  getAutoQuoteURL: 'https://wwww.<domain>.aaa.com/insurance/auto/olb',
  TravelReservationsURL: 'https://devaem.ace.aaa.com/travel/reservations.html',
  SafeliteURL: 'https://app.ace.aaa.com/membership/windshield',
  SafeliteEnabled: ['252', '215', '601'],
  PolicyViewAWSEnabled: {
    '252': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO'],
    '065': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO'],
    '004': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO', 'CALIFORNIA_EARTHQUAKE'],
    '018': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO'],
    '036': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO'],
    '215': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO'],
    '258': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO'],
    '601': ['AUTO', 'HOME', 'RENTAL_DWELLING', 'TENANT', 'CONDO'],
  },
  MembershipOverlaysEnabled: [],
  identityUsePkce: false, //made it false, yarn stubs throw an error with pkce
  InsuranceOverlaysEnabled: [],
  MembershipPaperlessEnabled: [],
  ManageUsernameEnabled: ['ALL'],
  ManageAddressChangeEnabled: ['ALL'],
  NewMembershipOptions: [
    {
      id: 'PAY_MY_BILL',
      value: 'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
      display: 'Pay my bill',
    },
    {
      id: 'MANAGE_MEMBERSHIP',
      value: 'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
      display: 'Manage my membership',
    },
    {
      id: 'GET_MEMBERSHIP_CARD',
      value: 'https://apps.<domain>.aaa.com/aceapps/replacementcard/request',
      display: 'Get membership card',
    },
    {
      id: 'MANAGE_AUTOMATIC_PAYMENTS',
      value:
        'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1&activity=AutoRenew',
      display: 'Manage Auto Pay',
    },
    {
      id: 'ENROLL_AUTOMATIC_PAYMENTS',
      value: 'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
      display: 'Enroll in Auto Pay',
    },
    {
      id: 'VIEW_MEMBER_GUIDE',
      value: 'https://www.<domain>.aaa.com/content/dam/ace/pdf/<abbreviation>-MemberGuide.pdf',
      display: 'View member guide',
    },
    {
      id: 'DONT_SEE',
      value: 'https://www.<domain>.aaa.com/membership/why-join-aaa.html#faqs',
      display: "Don't see what you're looking for?",
    },
  ],
  DefaultMembershipProductOptions: {
    PAY_MY_BILL: ['ALL'],
    MANAGE_MEMBERSHIP: ['ALL'],
    GET_MEMBERSHIP_CARD: ['ALL'],
    MANAGE_AUTOMATIC_PAYMENTS: ['ALL'],
    ENROLL_AUTOMATIC_PAYMENTS: ['ALL'],
    VIEW_MEMBER_GUIDE: ['ALL'],
    DONT_SEE: ['ALL'],
  },
  InsuranceOptions: [
    {
      id: 'PAY_MY_BILL',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
      display: 'Pay my bill',
      externalFields: {
        key: 'ONLINE_BILL_PAY',
      },
    },
    {
      id: 'GET_PROOF_OF_INSURANCE',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
      display: 'Get proof of insurance cards',
      externalFields: {
        key: 'POLICY_DETAILS',
      },
    },
    {
      id: 'COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
      display: 'Complete annual insurance questionnaire',
      externalFields: {
        key: 'POLICY_DETAILS',
      },
    },
    {
      id: 'GET_CLAIM_STATUS',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
      display: 'Get claim status',
      externalFields: {
        key: 'POLICY_DETAILS',
      },
    },
    {
      id: 'CHANGE_POLICY',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
      display: 'View & manage policy',
      externalFields: {
        key: 'POLICY_DETAILS',
      },
    },
    {
      id: 'CHANGE_MY_CONTACT_INFORMATION',
      value: 'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ContactInformation?startType=1',
      display: 'Change my contact information',
    },
    {
      id: 'ENROLL_AUTO_PAY',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
      display: 'Enroll in Auto Pay',
      externalFields: {
        key: 'ONLINE_BILL_PAY',
      },
    },
    {
      id: 'MANAGE_AUTO_PAY',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
      display: 'Manage Auto Pay',
      externalFields: {
        key: 'BILLING_SUMMARY',
      },
    },
    {
      id: 'MANAGE_PAYMENT_PLAN',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
      display: 'Enroll in monthly payment plan',
      externalFields: {
        key: 'POLICY_DETAILS',
      },
    },
    {
      id: 'VIEW_POLICY_DETAILS',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
      display: 'View policy',
      externalFields: {
        key: 'POLICY_DETAILS',
      },
    },
    {
      id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
      value: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
      display: 'View billing & payment history',
      externalFields: {
        key: 'BILLING_SUMMARY',
      },
    },
    {
      id: 'VIEW_ALL_INSURANCE_DISCOUNTS',
      value: 'https://www.<domain>.aaa.com/insurance/insurance-resources/insurance-discounts.html',
      display: 'View all AAA insurance discounts',
    },
    {
      id: 'DONT_SEE',
      value: 'https://www.<domain>.aaa.com/insurance/get-insurance/what-we-insure.html#faqs',
      display: "Don't see what you're looking for?",
    },
  ],
  InsuranceFeatures: {
    CTA: {
      WATERCRAFT: {
        VIEW_BILL: ['004', '018', '065', '601', '258', '252', '215'],
      },
      AUTO: {
        VIEW_BILL: ['ALL'],
      },
      HOME: {
        VIEW_BILL: ['ALL'],
      },
      CONDO: {
        VIEW_BILL: ['ALL'],
      },
      TENANT: {
        VIEW_BILL: ['ALL'],
      },
      RENTAL_DWELLING: {
        VIEW_BILL: ['ALL'],
      },
      CALIFORNIA_EARTHQUAKE: {
        VIEW_BILL: ['ALL'],
      },
      PERSONAL_UMBRELLA: {
        VIEW_BILL: ['ALL'],
      },
      CLASSIC: {
        VIEW_BILL: ['ALL'],
      },
    },
    InsuranceProductOptions: {
      WATERCRAFT: {
        PAY_MY_BILL: ['ALL'],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        VIEW_ALL_INSURANCE_DISCOUNTS: ['ALL'],
        DONT_SEE: ['ALL'],
      },
      AUTO: {
        PAY_MY_BILL: ['ALL'],
        GET_PROOF_OF_INSURANCE: ['ALL'],
        COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE: ['ALL'],
        GET_CLAIM_STATUS: ['ALL'],
        CHANGE_POLICY: ['ALL'],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        MANAGE_PAYMENT_PLAN: ['004'],
        ENROLL_AUTO_PAY: ['ALL'],
        DONT_SEE: ['ALL'],
      },
      HOME: {
        PAY_MY_BILL: ['ALL'],
        VIEW_POLICY_DETAILS: ['001', '018', '036', '065', '215', '252', '258', '601'],
        CHANGE_POLICY: ['004'],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        MANAGE_AUTO_PAY: [],
        DONT_SEE: ['ALL'],
      },
      CONDO: {
        PAY_MY_BILL: ['ALL'],
        MANAGE_AUTO_PAY: [],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        VIEW_POLICY_DETAILS: ['ALL'],
        CHANGE_POLICY: ['004'],
        VIEW_ALL_INSURANCE_DISCOUNTS: ['ALL'],
        DONT_SEE: ['ALL'],
      },
      TENANT: {
        PAY_MY_BILL: ['ALL'],
        MANAGE_AUTO_PAY: [],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        VIEW_POLICY_DETAILS: ['ALL'],
        VIEW_ALL_INSURANCE_DISCOUNTS: ['ALL'],
        DONT_SEE: ['ALL'],
      },
      RENTAL_DWELLING: {
        PAY_MY_BILL: ['ALL'],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        MANAGE_AUTO_PAY: [],
        VIEW_POLICY_DETAILS: ['ALL'],
        VIEW_ALL_INSURANCE_DISCOUNTS: ['ALL'],
        DONT_SEE: ['ALL'],
      },
      CALIFORNIA_EARTHQUAKE: {
        PAY_MY_BILL: ['ALL'],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        MANAGE_AUTO_PAY: [],
        VIEW_ALL_INSURANCE_DISCOUNTS: ['ALL'],
        DONT_SEE: ['ALL'],
      },
      PERSONAL_UMBRELLA: {
        PAY_MY_BILL: ['ALL'],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        MANAGE_AUTO_PAY: [],
        VIEW_ALL_INSURANCE_DISCOUNTS: ['ALL'],
        DONT_SEE: ['ALL'],
      },
      CLASSIC: {
        PAY_MY_BILL: ['ALL'],
        GET_CLAIM_STATUS: ['ALL'],
        MANAGE_PAYMENT_PLAN: ['ALL'],
        VIEW_PAYMENT_AND_BILLING_HISTORY: ['ALL'],
        ENROLL_AUTO_PAY: ['ALL'],
        MANAGE_AUTO_PAY: [],
        VIEW_ALL_INSURANCE_DISCOUNTS: ['ALL'],
        DONT_SEE: ['ALL'],
      },
    },
  },
  AutoInsuranceSubDisplays: [
    {
      display: 'Enroll in Auto Pay',
      subDisplay: [],
      subDisplayIndented: true,
    },
  ],
  HomeInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
    {
      display: 'View policy',
      subDisplay: [],
      subDisplayIndented: true,
    },
    {
      display: 'View & manage policy',
      subDisplay: [],
      subDisplayIndented: true,
    },
  ],
  CondoInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
    {
      display: 'View policy details',
      subDisplay: ['Print policy details'],
      subDisplayIndented: true,
    },
    {
      display: 'View & manage policy',
      subDisplay: ['Change mortgage company'],
      subDisplayIndented: true,
    },
  ],
  TenantInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
    {
      display: 'View policy details',
      subDisplay: ['Print policy details'],
      subDisplayIndented: true,
    },
  ],
  RentalDwellingInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
    {
      display: 'View policy details',
      subDisplay: ['Print policy details'],
      subDisplayIndented: true,
    },
  ],
  WatercraftInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
  ],
  UmbrellaInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
    {
      display: "Don't see what you're looking for?",
      subDisplay: [],
    },
  ],
  CEAInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
  ],
  classicInsuranceSubDisplays: [
    {
      display: 'Change my contact information',
      subDisplay: [],
    },
  ],
  MembershipOptions: [
    {
      id: 1,
      value: '',
      display: 'Pay my bill',
    },
    {
      id: 2,
      value: '',
      display: 'Manage my membership',
    },
    {
      id: 3,
      value: '',
      display: 'Get membership card',
    },
    {
      id: 4,
      value: '',
      display: 'Manage Auto Pay',
    },
    {
      id: 5,
      value: '',
      display: 'View member guide',
    },
    {
      id: 6,
      value: '',
      display: "Don't see what you're looking for?",
    },
  ],
  MembershipURLs: {
    membershipIDCardURL: 'https://apps.<domain>.aaa.com/aceapps/replacementcard/request',
    membershipChangeContactInfoURL:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ContactInformation?startType=1',
    compareMembershipURL: 'https://www.<domain>.aaa.com/membership/why-join-aaa.html',
    memberGuideURL: 'https://www.calif.aaa.com/content/dam/ace/pdf/<abbreviation>-MemberGuide.pdf',
    membershipFaqURL: 'https://www.<domain>.aaa.com/membership/why-join-aaa.html#faqs',
    membershipPayBillURL:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
    updatePaymentAccountsURL:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1&activity=AutoRenew',
    addRemoveRVMotorcycleURL:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
    updateFamilyMemberURL:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1&activity=Associate',
    changeMembershipTypeURL:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1&activity=SelectLevel',
    manageMembershipURL:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
    ManageAddressChangeURL: 'https://app.ace.aaa.com/acechatbot',
    membershipUpgradeURL: 'https://www.ace.aaa.com/membership/my-membership/tx-membership-faq.html#faqMemUpgradeWait',
  },
  InsuranceURLs: {
    faqURL: 'https://www.<domain>.aaa.com/insurance/insurance-resources/insurance-discounts.html#faqs',
    viewBillURL: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
    payBillURL: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    cancelScheduledPaymentURL: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
    discountURL: 'https://www.<domain>.aaa.com/insurance/insurance-resources/insurance-discounts.html',
    lifeInsuranceURL:
      'https://www.aaalife.com/eServices/eservices/account/login.jsp?cc=<clubcode>&sc=63&cmp=13CT<clubcode>01T&WT.mc_id=13CT<clubcode>01T',
    iQURLHuon: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    iQURLPolicyStar: 'https://services.<domain>.aaa.com/accountAccessICIQ.do?dispatch=loadAccountAccessICIQ',
    onlineBillPayRedirectURL: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    billingSummaryRedirectURL: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
    policyDetailsRedirectURL: 'https://apps.<domain>.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    policyDetailsAWSRedirectURL: 'https://api.ace.aaa.com/insurance-policy-info/flow-director',

    paperlessBillingURL: 'https://apps2.<domain>.aaa.com/paperless/confirmemail',
    paperlessBillingQuickOptInURL: 'https://apps2.<domain>.aaa.com/paperless/quickoptin',
    paperlessMemberCardOptInURL: 'https://apps.<domain>.aaa.com/aceapps/preferences/start?paccpt=WEBMANAGE&memcard=1',
    paperlessMagazineURL: 'https://apps.<domain>.aaa.com/aceapps/preferences/start?paccpt=WEBMANAGE&magmodal=1',
  },
  ApiEndpoints: {
    "customer-info": "dev/customer-info",
    "products": "dev/products",
  },
  ProductTypesRank: {
    MEMBERSHIP: 1,
    INSURANCE_QUESTIONNAIRE: 2,
    AUTO: 3,
    HOME: 4,
    CONDO: 5,
    TENANT: 6,
    RENTAL_DWELLING: 7,
    CALIFORNIA_EARTHQUAKE: 8,
    WATERCRAFT: 9,
    PERSONAL_UMBRELLA: 10,
    LIFE: 11,
    DEFAULT: 99,
  },
  FeatureFlags: ['StandInCustomerDetailRules_OFF'],
  InsuranceFeatureFlags: {
    IQ: ['CAA', 'TPA', 'NMA', 'MEA', 'NHA', 'VTA', 'MOA'],
    IQ_POLICY_STAR: ['A', 'P'],
    ManagePaymentPlan: [
      {
        AUTO: ['CAA9', 'CAAF', 'CAAT'],
      },
    ],
    MonthlyPaymentPlan: {
      AUTO: ['CAAM'],
    },
    ReinstatablePolicy: ['CAA', 'TPA', 'NMA', 'CAC'],
    PolicyHolderSavings: ['004'],
  },
  MembershipFeatureFlags: {
    Show7DayWaitMsg: ['ALL'],
  },
  Messages: {
    ModalDialogMessages: {
      MembershipFeaturesNoAccess: {
        description:
          'For security reasons, we only allow authorized members, such as policyholders, to make or modify a payment.',
      },
      InsuranceFeaturesNoAccess: {
        description:
          'For security reasons, we only allow authorized members, such as policyholders, to make or modify a payment.',
      },
      InsuranceFeaturesNonNamed: {
        description: 'For your security and privacy, policy information is available to the named insured only.',
      },
      InsuranceQuestionaireNoAccess: {
        description: 'For security reasons, we only allow authorized members to submit IQ.',
      },
      CancelledPolicyReinstatement: {
        description:
          '<React.Fragment>Your policy will be reinstated and effective once your payment successfully processes.<br /><br />Any accidents or losses that occurred during the cancellation period are not covered.</React.Fragment>',
        actionMessage: 'Did you have accidents or losses on or after <cancel_date>?',
      },
    },
    NotificationMessages: {
      PaymentPastDue: 'This payment is past due.',
      PaymentInTransit: 'Processing payment',
      CreditCardIsExpiring:
        'Your card is about to expire. Please provide updated card information to ensure we can process your payments.',
      CreditCardIsInvalid:
        'Your credit/debit card ending in <cc_last_four> is no longer valid and cannot be used for future payments. To avoid cancellation of your membership, please provide a valid credit/debit card.',
      CreditCardNotOnFile:
        'We have no card on file for your account. Please provide updated card information so we can process your payments.',
      InsuranceCreditCardExpiring:
        'The card associated with your Auto Pay is about to expire. Please pay your bill, and re-enroll in Auto Pay with your updated payment information.',
      InsuranceCreditCardInvalid:
        'Your credit/debit card ending in <cc_last_four>  is no longer valid and cannot be used for future payments. To avoid an interruption in your insurance coverage, please pay your bill, and re-enroll in Auto Pay with your updated payment information.',
      InsurancePastDueAutopay:
        'We recently attempted to charge your card on file, but were unable to process your payment. To avoid cancellation of your policy, please provide a valid card and make your past due payment.',
      InsuranceCancelled:
        'Your coverage has been cancelled. To continue your policy, you must make a payment. Maintaining continuous car insurance is one of the best ways to avoid out-of-pocket costs and quality for lower rates.',
      IQNotificationTitle: 'Please complete your annual <producttype> insurance questionnaire',
      IQNotificationMessage: 'To avoid losing your verified mileage discount, you need to complete your questionnaire.',
      CNPMessage:
        'This policy is pending cancellation on <CNPDate> at 12:01AM. To avoid a lapse in coverage, please pay now.',
      TRNPMessage:
        'This policy is pending non-renewal on <TRNPDate> at 12:01AM. To avoid a lapse in coverage, please pay now.',
      PolicyDividend: 'You received a Policyholder Savings Dividend last year in the amount of $<policy_dividend>',
    },
  },
  AwarenessCard: {
    ENROLL_AUTO_PAY_MEMBERSHIP: {
      clubs: ['ALL'],
      startDate: '0000-01-01',
      endDate: '9999-01-01',
      cardData: {
        productId: '',
        cardCode: 'ENROLL_AUTO_PAY_MEMBERSHIP',
        cardTitle: {
          text: 'Save yourself some time',
        },
        cardBody: {
          text: "Enroll in AAA Auto Pay today and you won't have to worry about missing a payment",
        },
        dismissable: true,
        options: [
          {
            key: '90_DAY',
            display: 'Remind me again in 90 days',
          },
          {
            key: 'NEVER_AGAIN',
            display: "Don't remind me again",
          },
        ],
        rank: 0,
      },
    },
    ENROLL_AUTO_PAY_AUTO: {
      clubs: ['ALL'],
      startDate: '0000-01-01',
      endDate: '9999-01-01',
      cardData: {
        productId: '',
        cardCode: 'ENROLL_AUTO_PAY_INSURANCE',
        cardTitle: {
          text: 'Save yourself some time',
        },
        cardBody: {
          text: "Enroll in AAA Auto Pay today and you won't have to worry about missing a payment",
        },
        dismissable: true,
        options: [
          {
            key: '90_DAY',
            display: 'Remind me again in 90 days',
          },
          {
            key: 'NEVER_AGAIN',
            display: "Don't remind me again",
          },
        ],
        rank: 1,
      },
    },
    ENROLL_PAPERLESS_MAGAZINE: {
      clubs: ['001', '018', '036', '065', '215', '252', '258', '601'],
      startDate: '0000-01-01',
      endDate: '9999-01-01',
      cardData: {
        productId: '',
        cardCode: 'ENROLL_PAPERLESS_MAGAZINE',
        cardTitle: {
          text: 'Opt out of the print AAA magazine and go paperless',
        },
        cardBody: {
          text: 'Looking to reduce clutter and go green? Opt out of receiving the print AAA magazine, and conveniently access our articles online.',
        },
        dismissable: true,
        options: [
          {
            key: '90_DAY',
            display: 'Remind me again in 90 days',
          },
          {
            key: 'NEVER_AGAIN',
            display: "Don't remind me again",
          },
        ],
        rank: 3,
      },
    },
    ENROLL_PAPERLESS: {
      clubs: ['018', '036', '065', '215', '252', '258', '601', '004'],
      startDate: '0000-01-01',
      endDate: '9999-01-01',
      cardData: {
        productId: '',
        cardCode: 'ENROLL_PAPERLESS',
        cardTitle: {
          text: 'Go paperless',
        },
        cardBody: {
          text: "Simplify your life and eliminate clutter by going paperless. You'll receive email alerts when your billing statements and documents are ready.",
        },
        dismissable: true,
        options: [
          {
            key: '90_DAY',
            display: 'Remind me again in 90 days',
          },
          {
            key: 'NEVER_AGAIN',
            display: "Don't remind me again",
          },
        ],
        rank: 4,
      },
    },
    GET_AUTO_QUOTE: {
      clubs: ['065', '252'],
      startDate: '0000-01-01',
      endDate: '9999-01-01',
      cardData: {
        productId: '',
        cardCode: 'GET_AUTO_QUOTE',
        cardTitle: {
          text: 'Get auto insurance from a name you trust.',
        },
        cardBody: {
          text: 'Enjoy auto insurance savings, service, & security. Our policyholders have counted on AAA for years to provide the legendary service we’re known for.',
        },
        dismissable: true,
        options: [
          {
            key: '90_DAY',
            display: 'Remind me again in 90 days',
          },
          {
            key: 'NEVER_AGAIN',
            display: "Don't remind me again",
          },
        ],
        rank: 5,
      },
    },
  },
  Maintenance: {
    ScheduleByDays: {
      '0': {
        StartTime: '03:00',
        EndTime: '05:00',
      },
      '1': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '2': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '3': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '4': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '5': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
      '6': {
        StartTime: '02:30',
        EndTime: '03:30',
      },
    },
    RoadsideUrl: 'http://apps.<domain>.aaa.com/aceapps/links/alp/AAARoadsideAssistance',
    HelpPhoneNumber: '1-800-222-4357',
  },
  ccpaURL: 'https://apps2.calif.aaa.com/cpa/request',
};

// bill plan description map -- application-billplandescription.json
export const configBillPlanDescriptions: ConfigProperties = {
  BillPlanDescription: {
    CAA9: '9 payment plan',
    CAAF: 'Paid in full',
    CAAM: '12 monthly payment plan',
    CAAT: '10 payment plan',
    CAC1: '2 payment plan',
    CAC4: '4 payment plan',
    CAC6: '6 payment plan',
    CACF: 'Paid in full',
    CAH6: '6 payment plan',
    CAH9: '9 payment plan',
    CAHE: 'Residential escrow plan',
    CAHI: 'Residential plan',
    CAHM: 'Residential mortgagee bill plan',
    CARA: '3 payment plan',
    CARI: '5 payment plan',
    CARO: '1 payment plan',
    CAU1: 'Paid in full',
    CAU3: '3 payment plan',
    CAW2: '2 payment plan',
    CAW3: '3 payment plan',
    CAW4: '4 payment plan',
    CAW6: '6 payment plan',
    CAW9: '9 payment plan',
    CAWF: 'Paid in full',
    CEA6: '6 payment plan',
    CEA9: '9 payment plan',
    CEAE: 'CEA escrow plan',
    CEAI: 'CEA plan',
    CEAM: 'CEA mortgagee bill plan',
    CERA: '3 payment plan',
    DE: '6 payment plan',
    DH: '12 payment plan',
    D1: 'Pay in full',
    D4: '4 payment plan',
    D5: '5 payment plan',
    HIA1: '1 payment plan',
    HIA4: '4 payment plan',
    HIA5: '5 payment plan',
    HIAF: 'Paid in full',
    HIH3: '3 payment plan',
    HIH6: '6 payment plan',
    HIH9: '9 payment plan',
    HIHA: '3 payment plan',
    HIHM: 'Residential mortgagee bill plan',
    HIU1: '1 payment plan',
    HIU3: '3 payment plan',
    MEA1: '1 payment plan',
    MEA5: '5 payment plan',
    MEAF: 'Paid in full',
    MEH3: '3 payment plan',
    MEH6: '6 payment plan',
    MEH9: '9 payment plan',
    MEHA: '3 payment plan',
    MEHM: 'Residential mortgagee bill plan',
    MEU1: '1 payment plan',
    MEU3: '3 payment plan',
    MEW2: '2 payment plan',
    MEW3: '3 payment plan',
    MEW6: '6 payment plan',
    MEWF: 'Paid in full',
    MOAF: 'Paid in full',
    MOAM: 'Monthly payment plan',
    MOA5: '5 monthly installments',
    MOHF: 'Paid in full',
    MOHM: 'Residential mortgagee bill plan',
    MOMH: 'Monthly payment plan',
    MOQB: 'Quarterly payment plan',
    NHA1: '1 payment plan',
    NHA5: '5 payment plan',
    NHAF: 'Paid in full',
    NHH3: '3 payment plan',
    NHH6: '6 payment plan',
    NHH9: '9 payment plan',
    NHHA: '3 payment plan',
    NHHM: 'Residential mortgagee bill plan',
    NHU1: '1 payment plan',
    NHU3: '3 payment plan',
    NHW2: '2 payment plan',
    NHW3: '3 payment plan',
    NHW6: '6 payment plan',
    NHWF: 'Paid in full',
    NMA5: '5 payment plan',
    NMAF: 'Paid in full',
    NMH3: '3 payment plan',
    NMH6: '6 payment plan',
    NMH9: '9 payment plan',
    NMHM: 'Residential mortgagee bill plan',
    NMU1: '1 payment plan',
    NMU3: '3 payment plan',
    PAA2: '2 payment plan',
    PAA4: '4 payment plan',
    PAA5: '5 payment plan',
    PAAF: 'Paid in full',
    PAH3: '3 payment plan',
    PAH6: '6 payment plan',
    PAH9: '9 payment plan',
    PAHA: '3 payment plan',
    PAHF: 'Paid in full',
    PAHM: 'Residential mortgagee bill plan',
    TXA9: '9 payment plan',
    TXAF: 'Paid in full',
    TXAT: '10 payment plan',
    TXH6: '6-payment installment plan',
    TXH9: '9 payment plan',
    TXHA: 'Residential mortgagee bill plan',
    TXHI: '3 payment plan',
    TXMI: '11 payment plan',
    TXQB: '4 payment plan',
    TXRA: '3 payment plan',
    TXSA: '2 payment plan',
    TXU1: '1 payment plan',
    TXU3: '3 payment plan',
    TXW2: '2 payment plan',
    TXW3: '3 payment plan',
    TXW6: '6 payment plan',
    TXWB: '4 payment plan',
    TXWF: 'Paid in full',
    VAAF: 'Paid in full',
    VAAT: '10 payment plan',
    VAH3: '3-payment installment plan',
    VAH6: '6-payment installment plan',
    VAH9: '9-payment installment plan',
    VAHA: '3-payment Installment Plan',
    VAHM: 'Residential mortgagee bill plan',
    VAQB: '4 payment plan',
    VASA: '2 payment plan',
    VTA1: '1 payment plan',
    VTA5: '5 payment plan',
    VTAF: 'Paid in full',
    VTH3: '3 payment plan',
    VTH6: '6 payment plan',
    VTH9: '9 payment plan',
    VTHA: '3 payment plan',
    VTHM: 'Residential mortgagee bill plan',
    VTU1: '1 payment plan',
    VTU3: '3 payment plan',
    VTW2: '2 payment plan',
    VTW3: '3 payment plan',
    VTW6: '6 payment plan',
    VTWF: 'Paid in full',
  },
};

export const configFixture = {
  [ClubCodes.CA]: configCA as ConfigResponseSource,
  [ClubCodes.TX]: configTX as ConfigResponseSource,
  [ClubCodes.NM]: configNM as ConfigResponseSource,
  [ClubCodes.HI]: configHI as ConfigResponseSource,
  [ClubCodes.NNE]: configNNE as ConfigResponseSource,
  [ClubCodes.EC]: configEC as ConfigResponseSource,
  [ClubCodes.TW]: configTW as ConfigResponseSource,
  [ClubCodes.ACMO]: configMO as ConfigResponseSource,
};
