import {
  AwarenessCardContractApp,
  ConfigContractApp,
  CustomerV2ContractApp,
  CustomerDetailsContractApp,
  InsuranceOptionsContractApp,
  MembershipOptionsContractApp,
  NotificationContractApp,
  RedirectContractApp,
  TravelBookingContractApp,
  UpdateUserPreferenceContractApp,
} from 'shared';
import { appEndpoints } from './RemoteEndpoints';
import { AwarenessCardRemoteApp, AwarenessCardStubApp } from './sources/AwarenessCardAppSources';
import { ConfigRemoteApp, ConfigStubApp } from './sources/ConfigAppSources';
import { CustomerDetailsRemoteApp, CustomerDetailsStubApp } from './sources/CustomerDetailsAppSources';
import { InsuranceOptionsRemoteApp } from './sources/InsuranceOptionsAppSources';
import { InsuranceOptionsStubApp, MembershipOptionsStubApp } from 'remotes';
import { MembershipOptionsRemoteApp } from './sources/MembershipOptionsAppSources';
import { NotificationRemoteApp, NotificationStubApp } from './sources/NotificationAppSources';
import { RedirectRemoteApp, RedirectStubApp } from './sources/RedirectAppSources';
import { TravelBookingRemoteApp, TravelBookingStubApp } from './sources/TravelBookingAppSources';
import { UpdateUserPreferenceRemoteApp, UpdateUserPreferenceStubApp } from './sources/UserPreferenceAppSources';
import { CustomerV2StubApp, CustomerV2RemoteApp } from './sources/CustomerV2AppSources';
import { ProductsContractApp } from 'shared/sources/ProductsContractApp';
import { CustomerContractApp } from 'shared/sources/CustomerContractApp';
import { CustomerRemoteApp, CustomerStubApp } from './sources/CustomerAppSources';
import { ProductsRemoteApp, ProductsStubApp } from './sources/ProductsSources';

interface ApiEndpoints {
  customerDetails?: string;
  awarenessCards?: string;
  notifications?: string;
  customer?: string;
  products?: string;
}

export interface Sources {
  awarenessCards: AwarenessCardContractApp;
  config: ConfigContractApp;
  configV3: ConfigContractApp;
  customerV2: CustomerV2ContractApp;
  customerDetails: CustomerDetailsContractApp;
  insuranceOptions: InsuranceOptionsContractApp;
  membershipOptions: MembershipOptionsContractApp;
  notifications: NotificationContractApp;
  redirect: RedirectContractApp;
  travelBooking: TravelBookingContractApp;
  updateUserPreferece: UpdateUserPreferenceContractApp;
  customer: CustomerContractApp;
  products: ProductsContractApp;
}

const stubs = {
  awarenessCards: AwarenessCardStubApp(appEndpoints.awarenessCards),
  config: ConfigStubApp(appEndpoints.config),
  configV3: ConfigStubApp(appEndpoints.config),
  customerV2: CustomerV2StubApp(appEndpoints.customerV2),
  customerDetails: CustomerDetailsStubApp(appEndpoints.customerDetails),
  insuranceOptions: InsuranceOptionsStubApp(appEndpoints.insuranceOptions),
  membershipOptions: MembershipOptionsStubApp(appEndpoints.membershipOptions),
  notifications: NotificationStubApp(appEndpoints.notifications),
  redirect: RedirectStubApp(appEndpoints.redirect),
  travelBooking: TravelBookingStubApp(appEndpoints.travelBooking),
  updateUserPreferece: UpdateUserPreferenceStubApp(appEndpoints.userPreference),
  // config: ConfigRemoteApp(appEndpoints.config), //temporarily switch to remote to cherry pick each api for integration
  customer: CustomerStubApp(appEndpoints.customer),
  products: ProductsStubApp(appEndpoints.products),
};

const remotes = (overrideEndpoints?: ApiEndpoints): Sources => {
  return {
    awarenessCards: AwarenessCardRemoteApp(overrideEndpoints?.awarenessCards || appEndpoints.awarenessCards),
    config: ConfigRemoteApp(appEndpoints.config),
    configV3: ConfigRemoteApp(appEndpoints.appConfigV3),
    customerV2: CustomerV2RemoteApp(appEndpoints.customerV2),
    customerDetails: CustomerDetailsRemoteApp(overrideEndpoints?.customerDetails || appEndpoints.customerDetails),
    insuranceOptions: InsuranceOptionsRemoteApp(appEndpoints.insuranceOptions),
    membershipOptions: MembershipOptionsRemoteApp(appEndpoints.membershipOptions),
    notifications: NotificationRemoteApp(overrideEndpoints?.notifications || appEndpoints.notifications),
    redirect: RedirectRemoteApp(appEndpoints.redirect),
    travelBooking: TravelBookingRemoteApp(appEndpoints.travelBooking),
    updateUserPreferece: UpdateUserPreferenceRemoteApp(appEndpoints.userPreference),
    customer: CustomerRemoteApp(overrideEndpoints?.customer || appEndpoints.customer),
    products: ProductsRemoteApp(overrideEndpoints?.products || appEndpoints.products),
  };
};

export function appSources(overrideEndpoints?: ApiEndpoints): Sources {
  return process.env.REACT_APP_SOURCES === 'stubs' ? stubs : remotes(overrideEndpoints);
}
